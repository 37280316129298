import React from 'react';
import Button from 'react-bootstrap/Button';

function Projects() {
  return (
    <div id="projects" className="container py-5">
      <h2>My Projects</h2>
      <div className="project-list">

      <div className="project-item">
          <a href="http://www.WhatsInTheBoxNY.com" target="_blank" rel="noopener noreferrer">
            <img className="project-logo" src="/Assets/WITB.png" alt="Gothic Getaways" />
          </a>
          <div className="project-info">
            <h3><a href="http://www.WhatsInTheBoxNY.com" target="_blank" rel="noopener noreferrer">What's In The Box</a></h3>
            <p>The *What's In The Box* band website is a dynamic platform that showcases the band’s identity and music. It features an events page for upcoming shows, a gallery of performance photos and videos, and a booking form for fan and venue inquiries. The site also includes links to the band’s social media and streaming platforms, ensuring accessibility and engagement across all devices.</p>
          </div>
        </div>

        <div className="project-item">
          <a href="http://www.GothicGetaways.com" target="_blank" rel="noopener noreferrer">
            <img className="project-logo" src="/Assets/Gothic.png" alt="Gothic Getaways" />
          </a>
          <div className="project-info">
            <h3><a href="http://www.GothicGetaways.com" target="_blank" rel="noopener noreferrer">Gothic Getaways</a></h3>
            <p>Gothic Getaways is a unique blog site that delves into the niche world of gothic destinations and attractions. The site features visually captivating images generated using AI technology, paired with finely curated content that offers readers deep insights into gothic travel experiences. The site is also optimized for mobile users, ensuring a seamless and responsive interface across all devices.</p>
            {/* <Button variant="primary" onClick={() => window.open("http://www.GothicGetaways.com", "_blank")}>Read More</Button> */}
          </div>
        </div>

        <div className="project-item">
          <a href="http://www.PetCarePurrsuit.com" target="_blank" rel="noopener noreferrer">
            <img className="project-logo" src="/Assets/PetCare.png" alt="Pet Care Purrsuit" />
          </a>
          <div className="project-info">
            <h3><a href="http://www.PetCarePurrsuit.com" target="_blank" rel="noopener noreferrer">Pet Care Purrsuit</a></h3>
            <p>Pet Care Purrsuit is a comprehensive resource for pet owners, offering a wide range of articles on pet care and product recommendations. The site integrates AI-generated visuals and a custom search engine to help users easily find information tailored to their needs. It also supports affiliate marketing, enhancing its commercial potential.</p>
            {/* <Button variant="primary" onClick={() => window.open("http://www.PetCarePurrsuit.com", "_blank")}>Read More</Button> */}
          </div>
        </div>

        <div className="project-item">
          <a href="https://boo-cursor.web.app/" target="_blank" rel="noopener noreferrer">
            <img className="project-logo" src="/Assets/Boo.png" alt="Boo" />
          </a>
          <div className="project-info">
            <h3><a href="https://boo-cursor.web.app/" target="_blank" rel="noopener noreferrer">Super Mario Animated Cursor</a></h3>
            <p>This project features an interactive animation inspired by the Boo character from the Super Mario franchise. The "mousemove" animation mimics Boo's behavior of chasing when the user's back is turned and freezing when confronted, with expressions changing based on cursor movements.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;